import $ from 'jquery';
import 'what-input';
// import Swiper, {Navigation, Pagination, Autoplay} from 'swiper';



// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
$(document).foundation();

document.querySelectorAll('.drop-btn').forEach(dropBtn => {
  dropBtn.addEventListener('mouseenter', function() {
    const dropdownId = this.getAttribute('data-toggle');
    
    const dropdownPane = document.getElementById(dropdownId);
    
    document.querySelectorAll('.dropdown-pane').forEach(pane => pane.classList.remove('is-active'));

    dropdownPane.classList.add('is-active');

    const firstTab = dropdownPane.querySelector('.tabs-title');
    const firstPanel = dropdownPane.querySelector('.tabs-panel');

    dropdownPane.querySelectorAll('.tabs-title').forEach(tab => tab.classList.remove('is-active'));
    dropdownPane.querySelectorAll('.tabs-panel').forEach(panel => panel.classList.remove('is-active'));

    if (firstTab) firstTab.classList.add('is-active');
    if (firstPanel) firstPanel.classList.add('is-active');
  });
});

document.querySelectorAll('.tabs-title').forEach(tab => {
  tab.addEventListener('mouseenter', function() {
    document.querySelectorAll('.tabs-title').forEach(t => t.classList.remove('is-active'));
    document.querySelectorAll('.tabs-panel').forEach(panel => panel.classList.remove('is-active'));

    this.classList.add('is-active');

    const targetPanel = this.querySelector('a').getAttribute('href');
    document.querySelector(targetPanel).classList.add('is-active');
  });
});


$(document).ready(function () {
    var $offCanvas = $('#mobileMenu');
  
    // When the off-canvas is opened
    $offCanvas.on('opened.zf.offCanvas', function () {
      $('body').addClass('is-locked');
    });
  
    // When the off-canvas is closed
    $offCanvas.on('closed.zf.offCanvas', function () {
      $('body').removeClass('is-locked');
    });
  });
  

// let closeBtn = document.querySelector(".closing");
// closeBtn.addEventListener("click", function() {
//     $('#mobileMenu').foundation('close');
// })

$('.close-button').on('click', function() {
  var offCanvas = new Foundation.OffCanvas($('#mobileMenu'));
  
  offCanvas.close();

  $('.js-off-canvas-overlay.is-overlay-fixed.is-visible.is-closable').remove();

  $('body').removeClass('is-locked');
});


// Sey equal height on carousel titles
window.onload = function() {
  const titles = document.querySelectorAll('.carousel-new-products .product-box .title-wrapper');
  
  let maxHeight = 0;
  
  titles.forEach(title => {
      const height = title.offsetHeight;
      if (height > maxHeight) {
          maxHeight = height;
      }
  });
  
  titles.forEach(title => {
      title.style.height = `${maxHeight}px`;
  });
};



document.querySelectorAll(".second-level").forEach((item) => {
  item.addEventListener("click", () => {
    let ulParent = item.closest('ul');
    ulParent.scrollTop = 0;
    ulParent.style.overflowY = 'hidden';
    
    let backBTNs = ulParent.querySelectorAll('li ul.nested .js-drilldown-back');
    
    // Check if the back button exists before adding event listener
    if (backBTNs) {
      backBTNs.forEach((backBTN) => {
        // backBTN.style.background = "red";
        backBTN.addEventListener('click', () => {
          ulParent.style.overflowY = 'scroll';
          console.log("Back button clicked, overflow restored");
        });
      })
    } else {
      console.log("Back button not found");
    }
  });
});
